<template>
  <div class="topology-box">
    <a-button icon="edit" type="link" @click="$router.push({ name: 'ProjectTopology', params: { id:$route.params.id } })"
      style="color: #faad14;float: right;margin-bottom: 10px;">编辑</a-button>
    <div style="clear: both;"></div>
    <div id="systemTopology"></div>
  </div>
</template>

<script>
import { Graph } from '@antv/x6'
import { getProjectTopology } from '@/api/project'
import { getSourceRouterName } from '@/utils'

export default {
  name: 'TopologyTab',
  data () {
    return {
      graph: null
    }
  },
  mounted () {
    this.initGraph()
    this.fetch()
  },
  methods: {
    initGraph () {
      const container = document.getElementById('systemTopology')
      this.graph = new Graph({
        container,
        grid: true,
        mousewheel: {
          enabled: true,
          modifiers: 'ctrl',
          minScale: 0.5,
          maxScale: 2
        },
        embedding: {
          enabled: false
        },
        interacting: {
          nodeMovable: false
        },
        connecting: {
          router: 'manhattan',
          connector: 'rounded'
        },
        scroller: false,
        panning: true
      })
      this.graph.on('node:click', ({ node }) => {
        if (!node.data.parent) {
          this.$router.push({
            name: getSourceRouterName(node.data.sourceType),
            params: { id: node.data.sourceId }
          })
          const elements = document.getElementsByClassName('ant-tooltip')
          for (const element of elements) {
            if (element.style.display !== 'none') {
              element.style.display = 'none'
            }
          }
        }
      })
    },
    fetch () {
      getProjectTopology(this.$route.params.id).then(res => {
        this.graph.fromJSON(res.data || {})
        this.graph.zoomToFit({ maxScale: 2 })
        this.graph.zoom(-0.1)
        this.graph.centerContent({ padding: { top: -200 } })
      })
    }
  }
}
</script>

<style lang="less">
.topology-box {
  overflow: hidden;

  .topology-page {
    margin-bottom: 20px;

    .ant-page-header-heading-title {
      // font-weight: normal;
      font-size: 16px;
    }
  }

  #systemTopology {
    height: 100vh;
    width: 100%;
  }
}
</style>
