<template>
  <div>
    <div style="margin-bottom: 16px;">
      <a-page-header title="系统" :ghost="false" :sub-title="detail.name">
        <template slot="tags">
          <project-status-badge :status="detail.status"></project-status-badge>
        </template>
        <template slot="extra">
          <a @click="$refs.updateForm.show(detail.id)" style="color: #faad14;">
            <a-space style="user-select: none;">
              <a-icon type="edit"></a-icon>
              <span>编辑</span>
            </a-space>
          </a>
          <update-form ref="updateForm" @ok="fetch"></update-form>
        </template>

        <a-descriptions :column="{ xs: 1, sm: 2, xl: 3, xxl: 4 }">
          <a-descriptions-item label="系统负责人">{{ detail.charger ? detail.charger.nickname : '-' }}</a-descriptions-item>
          <a-descriptions-item label="系统负责人">{{ detail.builder ? detail.builder.nickname : '-' }}</a-descriptions-item>
          <a-descriptions-item label="系统开始时间">{{ detail.started_at }}</a-descriptions-item>
          <a-descriptions-item label="系统截止时间">{{ detail.ended_at }}</a-descriptions-item>
        </a-descriptions>
      </a-page-header>
    </div>

    <a-card :active-tab-key="activeTabKey" :bordered="false" :tab-list="tabList"
      @tabChange="key => { this.activeTabKey = key }">
      <authorization-tab v-show="activeTabKey === 'authorization'"></authorization-tab>
      <topology-tab v-if="activeTabKey === 'topology'" :permission="permission"></topology-tab>
    </a-card>
  </div>
</template>

<script>
import { getProject } from '@/api/project'
import ProjectStatusBadge from '../modules/ProjectStatusBadge'
import UpdateForm from '../modules/UpdateForm'
import AuthorizationTab from './modules/AuthorizationTab'
import TopologyTab from './modules/TopologyTab.vue'
import { hasPermission } from '@/utils'

export default {
  name: 'SystemDetail',
  components: {
    AuthorizationTab,
    ProjectStatusBadge,
    UpdateForm,
    TopologyTab
  },
  data () {
    return {
      detail: {
        status: 'unknown'
      },
      activeTabKey: 'authorization',
      tabList: [{ key: 'authorization', tab: '授权用户' }, { key: 'topology', tab: '系统拓扑' }]
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    permission () {
      return {
        create: hasPermission('topology.create'),
        update: hasPermission('topology.update'),
        delete: hasPermission('topology.delete')
      }
    }
  },
  methods: {
    fetch () {
      getProject(this.$route.params.id).then(res => {
        this.detail = res.data
      })
    }
  }
}
</script>

<style>
</style>
