<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="dataSource"
      :loading="loading"
      :pagination="false"
      row-key="id"
      :row-selection="rowSelection"
      :scroll="{ x: scrollX }"
      style="margin-bottom: 16px;"
    >
      <template slot="nickname" slot-scope="record">
        <a-tooltip placement="topLeft" :title="record">{{ record }}</a-tooltip>
      </template>
    </a-table>
  </div>
</template>

<script>
import { getProjectAuthorizedUserList } from '@/api/project'

export default {
  name: 'AuthorizationTab',
  data () {
    return {
      columns: [
        {
          dataIndex: 'nickname',
          title: '姓名',
          width: 200,
          ellipsis: true,
          scopedSlots: { customRender: 'nickname' }
        },
        { dataIndex: 'phone_number', title: '电话号码', width: 200 },
        { dataIndex: 'email_address', title: '邮箱地址', width: 200 }
      ],
      dataSource: [],
      total: 0,
      loading: false,
      rowSelection: {
        selectedRowKeys: [],
        onChange: (selectedRowKeys) => {
          this.rowSelection.selectedRowKeys = selectedRowKeys
        }
      }
    }
  },
  computed: {
    scrollX () {
      let sum = 0
      this.columns.forEach((column) => {
        sum += column.width
      })
      return sum
    }
  },
  mounted () {
    this.fetch()
  },
  methods: {
    fetch () {
      this.loading = true
      getProjectAuthorizedUserList(this.$route.params.id).then((res) => {
        const data = res.data
        this.dataSource = data.data
        this.total = data.total
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
